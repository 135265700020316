import React from 'react';
import { Link } from 'react-router-dom';

let EditContact=()=>{
    return(
        <React.Fragment>
        <section className='edits-contact p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <p className='h4 text-primary fw-bold'>Edit Contact</p>
                        <p className='fst-italic'>Edit existing record of the contact list. Let’s see how to edit a contact list and 
                        follow the entire operation of  your contacts. A contact page should provide visitors with clear instructions 
                        on how to get in touch with your business. This includes a contact form, additional contact information 
                        <span className='text-danger fw-bold'>(e.g., phone number, email address),</span> and a brief introduction or message that highlights your commitment 
                        to customer service.</p>
                    </div>
                </div>
                <div className='row align-items-center'>
                    <div className='col-md-4'>
                        <form>
                            <div className='mb-2'>
                                <input type='text' className='form-control' placeholder='Name' />
                            </div>
                            <div className='mb-2'>
                                <input type='text' className='form-control' placeholder='Photo URL' />
                            </div>
                            <div className='mb-2'>
                                <input type='number' className='form-control' placeholder='Mobile No.' />
                            </div>
                            <div className='mb-2'>
                                <input type='email' className='form-control' placeholder='Email' />
                            </div>
                            <div className='mb-2'>
                                <input type='text' className='form-control' placeholder='Company' />
                            </div>
                            <div className='mb-2'>
                                <input type='text' className='form-control' placeholder='title' />
                            </div><div className='mb-2'>
                                <select className='form-control '>
                                    <option value="">Select a Group</option>
                                </select>
                            </div>
                            <div className='mb-2'>
                                <input type='submit' className='btn btn-primary my-2' value='Update' />
                                <Link to={'/contacts/list'} className='btn btn-dark ms-2'>Cancel</Link>
                            </div>
                        </form>
                    </div>
                    <div className='col-md-6'>
                    <img src='https://i.pinimg.com/1200x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg' alt='' className='contact-img' />
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
    )
};
export default EditContact;