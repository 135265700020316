import React from 'react';
import { Link } from 'react-router-dom';

let ViewContact=()=>{
    return(
        <React.Fragment>
            <section className='view-contact-intro' p-3>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <p className='h3 text-warning fw-bold'>View Contact</p>
                            <p className='fst-italic'>View existing record of the contact list. Let’s see how to view a contact list and 
                        follow the entire operation of  your contacts. A contact page should provide visitors with clear instructions 
                        on how to get in touch with your business. This includes a contact form, additional contact information 
                        <span className='text-danger fw-bold'>(e.g., phone number, email address),</span> and a brief introduction or message that highlights your commitment 
                        to customer service.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='view-contact mt-3'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-4'>
                        <img src='https://i.pinimg.com/1200x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg' alt='' className='contact-img' />
                        </div>
                        <div className='col-md-8'>
                            <ul className='list-group'>
                                <li className='list-group-item list-group-item-action'>
                                            Name : <span className='fw-bold fs-6'>Prasanna Samantaray</span>
                                </li>
                                <li className='list-group-item list-group-item-action'>
                                            Mobile : <span className='fw-bold fs-6'>+919338670056</span>
                                </li>
                                <li className='list-group-item list-group-item-action'>
                                            Email : <span className='fw-bold fs-6'>pksamantaray@gmail.com</span>
                                </li>
                                <li className='list-group-item list-group-item-action'>
                                            Company : <span className='fw-bold fs-6'>Essar Systems pvt. Ltd.</span>
                                </li>
                                <li className='list-group-item list-group-item-action'>
                                            Title : <span className='fw-bold fs-6'>Essar Prasanna</span>
                                </li>
                                <li className='list-group-item list-group-item-action'>
                                            Group : <span className='fw-bold fs-6'>O +ve</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <Link to={'/contacts/list'} className='btn btn-warning'>Back</Link>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
};
export default ViewContact;