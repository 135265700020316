import React from 'react';
import { Link } from 'react-router-dom';


let ContactList=()=>{
    
    return(
        <React.Fragment>
            <section className="contact-search p-3">
                <div className='container'>
                    <div className='grid'>
                        <div className='row'>
                            <div className='col'>
                                <p className='h3'>Contact Manager
                                <Link to={'/contacts/add'} className='btn btn-outline-primary ms-2'><i className='fa fa-circle-plus me-2' />Add Contact</Link>
                                </p>
                                <p className='fst-italic'>Your contact information is the information (such as your telephone number, address, or e-mail address) that tells someone how to communicate with you.
                                  Making sure visitors can easily get in touch with you is one of the easiest ways to generate leads. Good contact information makes you look accessible. 
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <form className='row'>
                                 <div className='col'>
                                   <div className='mb-2'>
                                        <input type='text' className='form-control' placeholder="Search Names" />
                                    </div>
                                 </div>
                                 <div className='col'>
                                    <div className='mb-2'>
                                        <input type='submit' className='btn btn-outline-info' value="Search" />
                                    </div>
                                 </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='contact-list'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row align-items-center d-flex justify-content-around'>
                                <div className='col-md-4'>
                                    <img src='https://i.pinimg.com/1200x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg' alt='' className='contact-img' />
                                </div>
                                <div className='col-md-7'>
                                    <ul className='list-group'>
                                        <li className='list-group-item list-group-item-action'>
                                            Name : <span className='fw-bold fs-6'>Prasanna Samantaray</span>
                                        </li>
                                        <li className='list-group-item list-group-item-action'>
                                            Mobile : <span className='fw-bold fs-6'>+919338670056</span>
                                        </li>
                                        <li className='list-group-item list-group-item-action'>
                                            Email : <span className='fw-bold fs-6'>pksamantaray@gmail.com</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-md-1 d-flex flex-column align-items-center'>
                                    <Link to={'/contacts/view/:contactId'} className='btn btn-warning'><i className='fa fa-eye' /></Link>
                                    <Link to={'/contacts/edit/:contactId'} className='btn btn-primary my-1'><i className='fa fa-pen' /></Link>
                                    <button className='btn btn-danger'><i className='fa fa-trash' /></button>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row align-items-center d-flex justify-content-around'>
                                <div className='col-md-4'>
                                    <img src='https://i.pinimg.com/1200x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg' alt='' className='contact-img' />
                                </div>
                                <div className='col-md-7'>
                                    <ul className='list-group'>
                                        <li className='list-group-item list-group-item-action'>
                                            Name : <span className='fw-bold fs-6'>Prasanna Samantaray</span>
                                        </li>
                                        <li className='list-group-item list-group-item-action'>
                                            Mobile : <span className='fw-bold fs-6'>+919338670056</span>
                                        </li>
                                        <li className='list-group-item list-group-item-action'>
                                            Email : <span className='fw-bold fs-6'>pksamantaray@gmail.com</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-md-1 d-flex flex-column align-items-center'>
                                    <Link to={'/contacts/view/:contactId'} className='btn btn-warning'><i className='fa fa-eye' /></Link>
                                    <Link to={'/contacts/edit/:contactId'} className='btn btn-primary my-1'><i className='fa fa-pen' /></Link>
                                    <button className='btn btn-danger'><i className='fa fa-trash' /></button>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='row mt-4'>
                    <div className='col-md-6'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row align-items-center d-flex justify-content-around'>
                                <div className='col-md-4'>
                                    <img src='https://i.pinimg.com/1200x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg' alt='' className='contact-img' />
                                </div>
                                <div className='col-md-7'>
                                    <ul className='list-group'>
                                        <li className='list-group-item list-group-item-action'>
                                            Name : <span className='fw-bold fs-6'>Prasanna Samantaray</span>
                                        </li>
                                        <li className='list-group-item list-group-item-action'>
                                            Mobile : <span className='fw-bold fs-6'>+919338670056</span>
                                        </li>
                                        <li className='list-group-item list-group-item-action'>
                                            Email : <span className='fw-bold fs-6'>pksamantaray@gmail.com</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-md-1 d-flex flex-column align-items-center'>
                                    <Link to={'/contacts/view/:contactId'} className='btn btn-warning'><i className='fa fa-eye' /></Link>
                                    <Link to={'/contacts/edit/:contactId'} className='btn btn-primary my-1'><i className='fa fa-pen' /></Link>
                                    <button className='btn btn-danger'><i className='fa fa-trash' /></button>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row align-items-center d-flex justify-content-around'>
                                <div className='col-md-4'>
                                    <img src='https://i.pinimg.com/1200x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg' alt='' className='contact-img' />
                                </div>
                                <div className='col-md-7'>
                                    <ul className='list-group'>
                                        <li className='list-group-item list-group-item-action'>
                                            Name : <span className='fw-bold fs-6'>Prasanna Samantaray</span>
                                        </li>
                                        <li className='list-group-item list-group-item-action'>
                                            Mobile : <span className='fw-bold fs-6'>+919338670056</span>
                                        </li>
                                        <li className='list-group-item list-group-item-action'>
                                            Email : <span className='fw-bold fs-6'>pksamantaray@gmail.com</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-md-1 d-flex flex-column align-items-center'>
                                    <Link to={'/contacts/view/:contactId'} className='btn btn-warning'><i className='fa fa-eye' /></Link>
                                    <Link to={'/contacts/edit/:contactId'} className='btn btn-primary my-1'><i className='fa fa-pen' /></Link>
                                    <button className='btn btn-danger'><i className='fa fa-trash' /></button>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </section>
        </React.Fragment>
    )

}; export default ContactList;