import React from 'react';
import { Link } from 'react-router-dom';

let AddContact=()=>{
    return(
        <React.Fragment>
            <section className='add-contact p-3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <p className='h4 text-success fw-bold'>Create Contact</p>
                            <p className='fst-italic'>Add New record to the contact list. Let’s see how to create a contact list and 
                            follow the entire operation of  your contacts. A contact page should provide visitors with clear instructions 
                            on how to get in touch with your business. This includes a contact form, additional contact information 
                            <span className='text-danger fw-bold'>(e.g., phone number, email address),</span> and a brief introduction or message that highlights your commitment 
                            to customer service.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <form>
                                <div className='mb-2'>
                                    <input type='text' className='form-control' placeholder='Name' />
                                </div>
                                <div className='mb-2'>
                                    <input type='text' className='form-control' placeholder='Photo URL' />
                                </div>
                                <div className='mb-2'>
                                    <input type='number' className='form-control' placeholder='Mobile No.' />
                                </div>
                                <div className='mb-2'>
                                    <input type='email' className='form-control' placeholder='Email' />
                                </div>
                                <div className='mb-2'>
                                    <input type='text' className='form-control' placeholder='Company' />
                                </div>
                                <div className='mb-2'>
                                    <input type='text' className='form-control' placeholder='title' />
                                </div><div className='mb-2'>
                                    <select className='form-control '>
                                        <option value="">Select a Group</option>
                                    </select>
                                </div>
                                <div className='mb-2'>
                                    <input type='submit' className='btn btn-success my-2' value='Create' />
                                    <Link to={'/contacts/list'} className='btn btn-dark ms-2'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
};
export default AddContact;